import React, { useState } from "react";
import { Link, graphql } from "gatsby";
import InfiniteScroll from "react-infinite-scroll-component";
import Img from "gatsby-image";
import { BlogJsonLd, GatsbySeo } from "gatsby-plugin-next-seo";

const Blog: React.FunctionComponent = ({ data }: any) => {

  var options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };

  let tagsonly = [];

  let tags = data.allDatoCmsBlog.edges;

  tags.forEach((element) => {
    tagsonly.push(element.node.category);
  });

  var tagssorted = tagsonly.reduce(function (p, c, i, a) {
    if (p.indexOf(c) == -1) p.push(c);
    else p.push();
    return p;
  }, []);

  const [count, setCount] = useState({
    prev: 0,
    next: 10,
  });
  const [prodotti, setProdotti] = useState(data.allDatoCmsBlog.edges);
  const [hasMore, setHasMore] = useState(true);
  const [output, setOutput] = useState([]);
  const [current, setCurrent] = useState(
    prodotti.slice(count.prev, count.next)
  );

  const getMoreData = () => {
    if (current.length === prodotti.length) {
      setHasMore(false);
      return;
    }

    setTimeout(() => {
      setCurrent(
        current.concat(prodotti.slice(count.prev + 10, count.next + 10))
      );
    }, 2000);

    
    setCount((prevState) => ({
      prev: prevState.prev + 10,
      next: prevState.next + 10,
    }));
  };

  return (
    <>
          <GatsbySeo
        title="Blog | Casa Fraine"
        description="Blog, notizie, fai da te, innovazione, tecnologia "
        canonical="https://www.casafraine.com/blog"
        openGraph={{
          url: "https://www.casafraine.com/blog",
          title: "Casa Fraine | Blog",
          description:
            "Blog, notizie, fai da te, innovazione, tecnologia",

          images: [
            {
              url:`${data.allDatoCmsBlog.edges[0]?.node.hero.fluid.src}`,
              width: 800,
              height: 600,
              alt: "Blog",
            },
            {
              url: `${data.allDatoCmsBlog.edges[0]?.node.hero.fluid.src}`,
              width: 900,
              height: 800,
              alt: "Blog",
            },
            { url: `${data.allDatoCmsBlog.edges[0]?.node.hero.fluid.src}` },
            { url: `${data.allDatoCmsBlog.edges[0]?.node.hero.fluid.src}` },
          ],
          site_name: "Casa Fraine",
        }}
        twitter={{
          handle: "@handle",
          site: "@site",
          cardType: "summary_large_image",
        }}
      />
      <BlogJsonLd
        url="https://casafraine.com/blog"
        headline="Il Nostro Blog"
        images={`https://www.datocms-assets.com/35618/1609972812-actnow.jpg`}
        posts={[
          {
            headline: `${data.allDatoCmsBlog.edges[0]?.node.title}`,
            image: `${data.allDatoCmsBlog.edges[0]?.node.hero.fluid.src}`,
          },
          {
            headline: `${data.allDatoCmsBlog.edges[1]?.node.title}`,
            image: `${data.allDatoCmsBlog.edges[1]?.node.hero.fluid.src}`,
          },
          {
            headline: `${data.allDatoCmsBlog.edges[2]?.node.title}`,
            image: `${data.allDatoCmsBlog.edges[2]?.node.hero.fluid.src}`,
          },
          {
            headline: `${data.allDatoCmsBlog.edges[3]?.node.title}`,
            image: `${data.allDatoCmsBlog.edges[3]?.node.hero.fluid.src}`,
          },
          {
            headline: `${data.allDatoCmsBlog.edges[4]?.node.title}`,
            image: `${data.allDatoCmsBlog.edges[4]?.node.hero.fluid.src}`,
          },
          {
            headline: `${data.allDatoCmsBlog.edges[5]?.node.title}`,
            image: `${data.allDatoCmsBlog.edges[5]?.node.hero.fluid.src}`,
          },
        ]}
        datePublished="2022-02-05T08:00:00+08:00"
        dateModified="2022-02-05T09:00:00+08:00"
        authorName="Vincenzo"
        description="Notizie interessanti trovate sul web... e non solo"
      />
      <div className="page-blog">
        <section className="container-content">
          <div>          
            <h2>Le ultime dal blog</h2>
            <InfiniteScroll
              dataLength={current.length}
              next={getMoreData}
              hasMore={hasMore}
              loader={<h4>Loading...</h4>}
            >
              {current.map((item, index) => {
                return (
                  <>
                    <article key={item.node.slug}>
                      <Img
                        className="small__image"
                        fluid={item.node.hero.fluid}
                        alt={item.node.title}
                      />
                      <div className="description">
                        <div className="description__top">
                          <Link
                            key={index}
                            replace
                            to={`/blog/${item.node.slug}`}
                          >
                            <h3>{item.node.title}</h3>
                            <br />
                            <time dateTime={item.node.meta.createdAt}>
                              {new Date(
                                item.node.meta.createdAt
                              ).toLocaleString("it-IT", options)}
                            </time>
                          </Link>

                          <Link
                            to={`/blog/categoria/${item.node.category.trim()}`}
                            className="category"
                          >
                            {item.node.category}
                          </Link>
                        </div>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: `<p>${item.node.excerpt.substring(0, 250).trim() + "..."
                              }<p>`,
                          }}
                        ></div>
                      </div>
                    </article>
                  </>
                );
              })}
            </InfiniteScroll>
          </div>

          <aside>
            <h3
              style={{
                width: "100%",
                fontSize: "1.8rem",
                margin: "1rem auto 0.8rem 0.5rem",
              }}
            >
              Tags
            </h3>
            {tagssorted.map((item, index) => {
              return (
                <>
                  <Link
                    key={index}
                    to={`/blog/categoria/${item.trim()}`}
                    className="category"
                  >
                    {item}
                  </Link>
                </>
              );
            })}
            <br />
            <br />   
          </aside>

        </section>

      </div>
    </>
  );
};
export default Blog;

export const query = graphql`
  query BlogQuery {
    allDatoCmsBlog {
      edges {
        node {
          title
          authorImage
          description
          slug
          category
          excerpt
          meta {
            createdAt
          }
          hero {
            fluid(maxWidth: 450, imgixParams: { fm: "jpg", auto: "compress" }) {
              ...GatsbyDatoCmsSizes
            }
          }
        }
      }
    }
  }
`;
